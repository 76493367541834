import { render, staticRenderFns } from "./EditDraftArticleContainer.vue?vue&type=template&id=3dc9005d&scoped=true&"
import script from "./EditDraftArticleContainer.vue?vue&type=script&lang=js&"
export * from "./EditDraftArticleContainer.vue?vue&type=script&lang=js&"
import style0 from "./EditDraftArticleContainer.vue?vue&type=style&index=0&id=3dc9005d&lang=scss&scoped=true&"
import style1 from "./EditDraftArticleContainer.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc9005d",
  null
  
)

export default component.exports